import React from 'react'
import { useSelector } from 'react-redux'

import PageTemplate from '../../templates/Page'
import { selectors as AppSelectors } from '../../redux/app/redux'
import withMemo from '../../decorators/WithMemo'

import PageSagas from './sagas'
import { selectors } from './redux'


const Page = () => {
  const data = useSelector(selectors.data)
  const isLoaded = useSelector(selectors.isLoaded)
  const breadcrumb = useSelector(AppSelectors.breadcrumb)

  if (data === null || isLoaded === false) {
    return null
  }

  return (
    <PageTemplate
      data={data}
      breadcrumb={breadcrumb}
    />
  )
}

Page.getInitialProps = async(ctx, store) => (
  store.saga.run(PageSagas.init, ctx).done
)

Page.propTypes = {}

Page.defaultProps = {}


export default withMemo()(Page)
