import { desktop, tablet } from '../../theme'


export default {
  container: {
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    paddingTop: '6.4rem',
    ...desktop({
      paddingTop: '8rem',
    }),
  },
  firstBlock: {
    paddingTop: '22rem',
    '&.hasTitle': {
      paddingTop: '24rem',
    },
    '&.hasStats': {
      paddingTop: '22rem',
    },
    '&.hasTitle.hasStats': {
      paddingTop: '24rem',
    },
    ...tablet({
      paddingTop: '8rem',
      '&.hasTitle': {
        paddingTop: '12rem',
      },
      '&.hasStats': {
        paddingTop: '14rem',
      },
      '&.hasTitle.hasStats': {
        paddingTop: '14rem',
      },
    }),
    ...desktop({
      paddingTop: '12rem',
      '&.hasTitle': {
        paddingTop: '14rem',
      },
      '&.hasStats': {
        paddingTop: '16rem',
      },
      '&.hasTitle.hasStats': {
        paddingTop: '18rem',
      },
    }),
  },
}
