import _empty from 'lodash/isEmpty'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import QueryString from '../../helpers/QueryString'
import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import routes, { Router } from '../../routes'
import { directoryType, directoryTypeOptions } from '../../graphql/enums/DirectoryType'
import { offerSourceOptions } from '../../graphql/enums/offerSource'
import { transformOffersSlugFilters } from '../../helpers/OffersFiltersHelpers'
import { transformPostsSlugFilters } from '../../helpers/PostsFiltersHelpers'
import { getSlugFiltersRegex, transformFiltersFromSlug } from '../../helpers/FilterHelpers'
import { companyFiltersConfig, profileFiltersConfig } from '../DirectoryList/config'
import { offersFiltersConfig } from '../OffersResults/config'

import Search from './index'
import { searchTypes } from './types'


function SearchWrapper() {
  const { t } = useTranslation()
  const searchBlock = useSelector(AppSelectors.searchBlock)
  const pages = useSelector(AppSelectors.pages)
  const route = useSelector(AppSelectors.route)
  const isOfferSearch = !_empty(searchBlock) && searchBlock?.type === searchTypes.SEARCH_BAR_OFFER

  let endpointRoute

  let filters = []

  let initialValue = {}

  if (searchBlock?.type === searchTypes.SEARCH_BAR_COMPANY) {
    endpointRoute = pages[pageType.DIRECTORY]
    const dto = directoryTypeOptions(t)

    filters = [
      {
        label: t('searchBlock_directory_typeLabel'),
        name: 'type',
        options: dto,
      },
    ]
    initialValue = {
      ...initialValue,
      type: dto[0]?.value,
    }
  }

  if (searchBlock?.type === searchTypes.SEARCH_BAR_OFFER) {
    endpointRoute = pages[pageType.OFFERS]
    const dto = offerSourceOptions(t)

    filters = [
      {
        label: t('searchBlock_offer_sourceLabel'),
        name: 'source',
        options: dto,
      },
    ]
    initialValue = {
      ...initialValue,
      source: dto[0]?.value,
    }
  }

  const asPath = route.asPath.replace(/.*\?/, '').replace(route.query.slug, '')
  const newParams = QueryString.parse(asPath)

  const offerSlugFilters = transformOffersSlugFilters(route.asPath)
  const postsSlugFilters = transformPostsSlugFilters(route.asPath)

  const filterConfig = [
    ...offersFiltersConfig(t),
    ...companyFiltersConfig([], [], [], t),
    ...profileFiltersConfig([], t),
  ]
  const slugFiltersList = filterConfig?.filter((f) => f.isPath)?.map((f) => f.slug)
  const regex = getSlugFiltersRegex(slugFiltersList)
  const slugFilters = transformFiltersFromSlug(route.asPath, regex)

  initialValue = {
    ...initialValue,
    ...offerSlugFilters,
    ...postsSlugFilters,
  }

  if (newParams.s && newParams.s !== '') {
    initialValue = {
      ...initialValue,
      keyword: newParams.s,
    }
  }
  if (newParams.type && newParams.type !== '') {
    initialValue = {
      ...initialValue,
      type: newParams.type,
    }
  }
  if (newParams.source && newParams.source !== '') {
    initialValue = {
      ...initialValue,
      source: newParams.source,
    }
  }

  initialValue = {
    ...initialValue,
  }

  const [searchValue, setValue] = useState(initialValue)

  const handleChange = useCallback(({ value }) => {
    setValue(value)
  }, [])

  const handleSubmit = useCallback(() => {
    let endpointFinal = endpointRoute?.link

    const qs = QueryString.stringify({
      ...searchValue?.keyword && {
        s: searchValue?.keyword,
      },
    })

    if (searchValue?.source && searchValue?.source !== '' && searchValue?.source !== 'empty') {
      endpointFinal = endpointFinal.replace(/\/source-[^/.]+/g, '')
      endpointFinal += `/source-${searchValue.source.trim()}`
    }
    if (searchValue?.type && searchValue?.type !== '' && searchValue?.type !== 'empty' && route.query.slug.includes(pages[pageType.DIRECTORY].link)) {
      endpointFinal = endpointFinal.replace(/\/type-[^/.]+/g, '')
      endpointFinal += `/type-${searchValue.type.trim()}`
    }

    if (
      !route.query.slug.includes(pages[pageType.DIRECTORY].link)
      && !route.query.slug.includes(pages[pageType.OFFERS].link)) {
      endpointFinal += `/${Object.keys(slugFilters)?.map((k) => `${k}-${slugFilters[k]}`).join('/')}`.trimEnd()
    }

    if (qs && qs !== '') {
      endpointFinal += `?${qs}`
    }

    const endpointUrl = Router.getRouteUrl(routes.page, { slug: endpointFinal })

    Router.pushRoute(endpointUrl)
  }, [endpointRoute, pages, route.query.slug, searchValue, slugFilters])

  const searchProps = {
    ...searchBlock,
    placeholder: isOfferSearch ? t('searchBlock_offer_placeholder') : (searchValue?.type === directoryType.COMPANY ? t('searchBlock_company_placeholder') : t('searchBlock_profile_placeholder')),
    label: t('searchBlock_submitLabel'),
  }

  return (
    <Search
      {...searchProps}
      filters={filters}
      value={searchValue}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  )
}

SearchWrapper.propTypes = Search.propTypes

SearchWrapper.defaultProps = Search.defaultProps

export default withMemo()(SearchWrapper)
