import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import _empty from 'lodash/isEmpty'
import cx from 'classnames'

import Banner from '../../components/Banner'
import HeaderWrapper from '../../components/Header/wrapper'
import FooterWrapper from '../../components/Footer/wrapper'
import SearchWrapper from '../../components/Search/wrapper'
import { Breadcrumb } from '../../components'
import { BlocksParser } from '../../helpers/BlocksHelpers'
import { selectors as AppSelectors } from '../../redux/app/redux'
import withMemo from '../../decorators/WithMemo'
import usePWAInstallPrompt from '../../hooks/usePWAInstallPrompt'

import styles from './styles'


const useStyles = createUseStyles(styles)

// eslint-disable-next-line complexity
function PageTemplate(props) {
  usePWAInstallPrompt()


  const classes = useStyles(props)
  const bannerBlock = useSelector(AppSelectors.bannerBlock)
  const routeSelector = useSelector(AppSelectors.route)
  const searchBlock = useSelector(AppSelectors.searchBlock)
  const { data, breadcrumb, children } = props

  const isHome = useMemo(() => routeSelector.asPath === '/', [routeSelector.asPath])
  const isFullScreen = ['CountdownBlock'].includes(data?.blocks?.[0]?.__typename)

  return (
    <div className={classes.container}>
      {!isFullScreen && <HeaderWrapper />}
      {!isFullScreen && !isHome && (
        <Breadcrumb
          entries={breadcrumb}
          className={classes.breadcrumb}
        />
      )}
      {!isFullScreen && !_empty(bannerBlock) && (
        <Banner
          {...bannerBlock}
          hasSearch={!_empty(searchBlock)}
          hasSearchTitle={!!(!_empty(searchBlock) && searchBlock.title)}
          hasSearchStats={!!(!_empty(searchBlock) && searchBlock.stats)}
        >
          {!_empty(searchBlock) && (
            <SearchWrapper />
          )}
        </Banner>
      )}
      {!isFullScreen && children}
      {data && (
        <BlocksParser
          blocks={data.blocks}
          firstBlockClassname={!_empty(searchBlock) && cx(
            classes.firstBlock,
            searchBlock.title && 'hasTitle',
            searchBlock.stats && 'hasStats'
          )}
        />
      )}
      {!isFullScreen && <FooterWrapper />}
    </div>
  )
}

PageTemplate.styles = styles

PageTemplate.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  breadcrumb: PropTypes.array,
  children: PropTypes.node,
}

PageTemplate.defaultProps = {
  data: {},
  breadcrumb: null,
  children: null,
}

export default withMemo()(PageTemplate)
